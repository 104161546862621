import React, { useEffect, useState } from 'react';
import { Box, SpaceBetween, Cards as CloudscapeCards, Multiselect } from "@cloudscape-design/components"
import Link from '../utils/Link';


const sortList = (list) => {
    if (Array.isArray(list)) {
        if (list.every(item => typeof item === "string")) {
            return list.slice().sort((a, b) => a.localeCompare(b));
        } else if (list.every(item => typeof item === "object" && item.hasOwnProperty("name"))) {
            return list.slice().sort((a, b) => a.name.localeCompare(b.name));
        }
    }
    return list;
};

const createList = (inputList) => {
    const outputList = [];
    inputList.forEach((item) => {
        if (typeof item === "string") {
            outputList.push({ name: item });
        } else if (typeof item === "object" && item.hasOwnProperty("name")) {
            outputList.push(item);
        }
    });
    return outputList;
};

const cardDefinition = (type) => {
    if (type === "knownhost") {
        return {
            header: item => (
                <Link to={`/${type}/${item.name}/${item.algorithm}`} fontSize="heading-m">
                    {item.name}
                </Link>
            ),
            sections: [
                {
                    id: "algorithm",
                    header: "Algorithm",
                    content: item => item.algorithm
                }
            ]
        };
    } else if (type === "publickey") {
        return {
            header: item => (
                <Link to={`/${type}/${item.name}/${item.algorithm}`} fontSize="heading-m">
                    {item.name}
                </Link>
            ),
            sections: [
                {
                    id: "algorithm",
                    header: "Algorithm",
                    content: item => item.algorithm
                },
                {
                    id: "host_hash",
                    header: "Host",
                    content: item => item.host_hash
                }

            ]
        };
    } else {
        return {
            header: item => (
                <Link to={`/${type}/${item.name}`} fontSize="heading-m">
                    {item.name}
                </Link>
            ),
            sections: []
        };
    }
};

export const Cards = ({ type, loading, list = [], header = {} }) => {
    const sortedList = sortList(list);
    return (
        <CloudscapeCards
            ariaLabels={{
                itemSelectionLabel: (e, t) => `select ${t.name}`,
                selectionGroupLabel: "Item selection"
            }}
            cardDefinition={cardDefinition(type)}
            cardsPerRow={[
                { cards: 1 },
                { minWidth: 500, cards: 2 },
                { minWidth: 750, cards: 3 },
                { minWidth: 1000, cards: 4 }
            ]}
            items={
                createList(sortedList)
            }
            loading={loading}
            loadingText={`Loading ${type}s`}
            empty={
                <Box
                    margin={{ vertical: "xs" }}
                    textAlign="center"
                    color="inherit"
                >
                    <SpaceBetween size="m">
                        <b>No {type}</b>
                    </SpaceBetween>
                </Box>
            }
            header={header}
        />
    )
};

export const CardsEdit = ({ type, initial = [], list = [], updateFunction = null }) => {
    const transformInitial = (initial) => {
        return initial.map((itemname) => (
            {
                label: itemname,
                value: itemname
            }
        ));
    }

    const [selectedOptions, setSelectedOptions] = useState(transformInitial(initial));

    const updateSelectedOptions = (options) => {
        setSelectedOptions(options);
        if (updateFunction) {
            updateFunction(options.map(option => option.value));
        }
    }

    useEffect(() => {
        setSelectedOptions(transformInitial(initial));
    }, [initial]);

    return (
        <Multiselect
            selectedOptions={selectedOptions}
            onChange={({ detail }) =>
                updateSelectedOptions(detail.selectedOptions)
            }
            options={
                list.map((itemname) => (
                    {
                        label: itemname,
                        value: itemname
                    }
                ))
            }
            placeholder={`Choose ${type}`}
        />
    );
};

export default Cards;
