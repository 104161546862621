import React, { useEffect, useState } from 'react';
import { fetchHostList, fetchHost, postHost, deleteHost } from '../utils/apiHelper';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Header, Container, SpaceBetween, Input } from "@cloudscape-design/components"
import { VariableList, VariableUpdate } from './Variable';
import Button from '../utils/Button';
import { Cards, CardsEdit } from '../utils/Cards';


export const HostList = ({ sessionToken = null, hosts = [], create = true }) => {
    const [stateHosts, setHosts] = useState(hosts);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!sessionToken) {
            setHosts(hosts);
        }
    }, [sessionToken, hosts]);

    useEffect(() => {
        fetchHostList(sessionToken, setHosts, setError);
    }, [sessionToken]);

    return (
        <Container key={6}>
            {error && <p>Error: {error.message}</p>}
            <Cards type="host" list={stateHosts} header={
                <Header actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        {create ? <Button to="create">New Host</Button> : ''}</SpaceBetween>
                }>Hosts</Header>
            } />
        </Container>
    )
};


export const HostListEdit = ({ sessionToken, initial = [], updateFunction = null }) => {
    const [stateHosts, setHosts] = useState(null);
    const [selectedHosts, setSelectedHosts] = useState(initial);
    const [error, setError] = useState(null);

    const handleListChange = (hosts) => {
        setSelectedHosts(hosts);
        if (updateFunction) {
            updateFunction(hosts);
        }
    };

    useEffect(() => {
        fetchHostList(sessionToken, setHosts, setError);
    }, [sessionToken]);

    useEffect(() => {
        setSelectedHosts(initial);
    }, [initial]);

    return (
        <SpaceBetween size='m'>
            <Header>Hosts</Header>
            {error && <p>Error: {error.message}</p>}
            {stateHosts && <CardsEdit type="host" list={stateHosts} initial={selectedHosts} updateFunction={handleListChange} />}
        </SpaceBetween>
    )
};


export const Host = ({ sessionToken }) => {
    const { name } = useParams(); // Extract the name parameter from the route
    const [stateHost, setHost] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchHost(sessionToken, name, setHost, setError);
    }, [name, sessionToken]); // Dependency array includes name and sessionToken to re-fetch if they change

    return (
        <Container key={7}>
            {error && <p>Error: {error.message}</p>}
            <Box>
                {stateHost ? (
                    <SpaceBetween size='m'>
                        <Container><Header actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button to="edit">Edit</Button>
                                <Button to="delete">Delete</Button>
                            </SpaceBetween>
                        }>{stateHost.name}</Header></Container>
                        <Container><VariableList variables={stateHost.vars} /></Container>
                    </SpaceBetween>
                ) : (
                    <p>Loading...</p> // Handling the case when hostData is undefined
                )}
            </Box>
        </Container>

    );
};

export const HostEdit = ({ sessionToken }) => {
    const { name } = useParams(); // Extract the name parameter from the route
    const [stateHost, setHost] = useState(null);
    const [error, setError] = useState(null);
    const [fetchTrigger, setFetchTrigger] = useState(0); // State to trigger re-fetch
    const navigate = useNavigate();

    const handleVariablesChange = (newVars) => {
        setHost({ ...stateHost, newvars: newVars });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newData = {
            name: stateHost.name,
            vars: stateHost.newvars || stateHost.vars,
        }
        postHost(sessionToken, newData, null, setError).then(() => {
            navigate('/hosts');
        }).catch(err => {
            setError(err);
        });
    };

    const handleReset = (e) => {
        e.preventDefault();
        setFetchTrigger(prev => prev + 1);
    };

    useEffect(() => {
        fetchHost(sessionToken, name, setHost, setError);
    }, [name, sessionToken, fetchTrigger]);

    return (
        <Container key={7}>
            {error && <p>Error: {error.message}</p>}
            <Box>
                <form onSubmit={handleSubmit}>
                    {stateHost ? (
                        <SpaceBetween size='m'>
                            <Container><Header>{stateHost.name}</Header></Container>
                            <Container>
                                <VariableUpdate variables={stateHost.vars} updateFunction={handleVariablesChange} />
                            </Container>
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button type="submit">Submit</Button>
                                <Button onClick={handleReset}>Reset</Button>
                            </SpaceBetween>
                        </SpaceBetween>
                    ) : (
                        <p>Loading...</p> // Handling the case when hostData is undefined
                    )}
                </form>
            </Box>
        </Container>
    );
};

export const HostNew = ({ sessionToken }) => {
    const [stateHost, setHost] = useState({ name: '', vars: {} });
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleNameChange = (name) => {
        setHost({ ...stateHost, name: name });
    };

    const handleVariablesChange = (newVars) => {
        setHost({ ...stateHost, newvars: newVars });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newData = {
            name: stateHost.name,
            vars: stateHost.newvars || stateHost.vars,
        }
        postHost(sessionToken, newData, null, setError).then(() => {
            navigate('/hosts');
        }).catch(err => {
            setError(err);
        });
    };

    return (
        <Container key={7}>
            {error && <p>Error: {error.message}</p>}
            <Box>
                <form onSubmit={handleSubmit}>
                    <SpaceBetween size='m'>
                        <Container>
                            <SpaceBetween size='m'>
                                <Header>Name</Header>
                                <Input
                                    onChange={({ detail }) => handleNameChange(detail.value)}
                                    value={stateHost.name}
                                    ariaRequired
                                    autoFocus
                                    disableBrowserAutocorrect
                                />
                            </SpaceBetween>
                        </Container>
                        <Container>
                            <VariableUpdate variables={stateHost.vars} updateFunction={handleVariablesChange} />
                        </Container>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button type="submit">Submit</Button>
                        </SpaceBetween>
                    </SpaceBetween>
                </form>
            </Box>
        </Container>
    );
};

export const HostDelete = ({ sessionToken }) => {
    const { name } = useParams(); // Extract the name parameter from the route
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        deleteHost(sessionToken, name, null, setError).then(() => {
            navigate('/hosts');
        }).catch(err => {
            setError(err);
        });
    };

    return (
        <Container key={7}>
            {error && <p>Error: {error.message}</p>}
            <Box>
                <form onSubmit={handleSubmit}>
                    <SpaceBetween size='m'>
                        <Container><Header>Remove host {name}</Header></Container>
                        <Container>
                            Are you sure you want to remove host {name}?
                        </Container>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button type="submit">Remove</Button>
                        </SpaceBetween>
                    </SpaceBetween>
                </form>
            </Box>
        </Container>
    );
};

export default Host;
