import { Outlet, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { AppLayout, SideNavigation } from '@cloudscape-design/components';

const Layout = () => {
    const [activeHref, setActiveHref] = useState("/")
    useEffect(() => {
        setActiveHref("/")
    }, [])

    let navigate = useNavigate()

    return (
        <AppLayout key={3}
            headerSelector="#h"
            toolsHide={true}
            disableContentPaddings={false}
            navigationHide={false}
            navigation={
                <SideNavigation
                    activeHref={activeHref}

                    header={
                        { href: "/", text: "Ansible" }
                    }
                    items={
                        [
                            {
                                type: "section", text: "Dashboards", items: [
                                    { type: "link", href: "/inventory/ansible", text: "Ansible" },
                                    { type: "link", href: "/inventory/nagios", text: "Nagios" },
                                    { type: "link", href: "/inventory/raw", text: "Raw Inventory" },
                                    { type: "link", href: "/inventory/knownhosts", text: "Known Hosts" },
                                    { type: "link", href: "/inventory/publickeys", text: "Public Keys" },

                                ]
                            },
                            { type: "divider" },
                            {
                                type: "section", text: "Entities", items: [
                                    { type: "link", href: "/hosts", text: "Hosts" },
                                    { type: "link", href: "/groups", text: "Groups" },
                                    { type: "link", href: "/knownhosts", text: "Known Hosts" },
                                    { type: "link", href: "/publickeys", text: "Public Keys" },
                                ]
                            },
                        ]
                    }
                    onFollow={event => {
                        if (!event.detail.external) {
                            event.preventDefault();
                            console.log(event.detail.href)
                            setActiveHref(event.detail.href)
                            navigate(event.detail.href)
                        }
                    }}
                />
            }
            content={<Outlet />}
        />)
}

export default Layout
