import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Button as CloudscapeButton } from "@cloudscape-design/components";

const Button = ({ to, children, ...props }) => {
    const navigate = useNavigate();

    return (
        <CloudscapeButton onClick={() => navigate(to)} {...props}>{children}</CloudscapeButton>
    );
};

export default Button;
