import React, { useEffect, useState } from 'react';
import { fetchGroup, fetchGroupList, postGroup, deleteGroup } from '../utils/apiHelper';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Header, Container, SpaceBetween, Input } from "@cloudscape-design/components"
import { HostList, HostListEdit } from './Host';
import { VariableList, VariableUpdate } from './Variable';
import { Cards, CardsEdit } from '../utils/Cards';
import Button from '../utils/Button';


export const GroupList = ({ sessionToken = null, groups = [], create = true }) => {
    const [stateGroups, setGroups] = useState(groups);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!sessionToken) {
            setGroups(groups);
        }
    }, [sessionToken, groups]);

    useEffect(() => {
        fetchGroupList(sessionToken, setGroups, setError);
    }, [sessionToken]);

    return (
        <Container key={4}>
            {error && <p>Error: {error.message}</p>}
            <Cards type="group" list={stateGroups} header={
                <Header actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        {create ? <Button to="create">New Group</Button> : ''}</SpaceBetween>
                }>Groups</Header>
            } />
        </Container>
    )
};


export const GroupListEdit = ({ sessionToken, initial = [], updateFunction = null }) => {
    const [stateGroups, setGroups] = useState(null);
    const [selectedGroups, setSelectedGroups] = useState(initial);
    const [error, setError] = useState(null);

    const handleListChange = (groups) => {
        setSelectedGroups(groups);
        if (updateFunction) {
            updateFunction(groups);
        }
    };

    useEffect(() => {
        fetchGroupList(sessionToken, setGroups, setError);
    }, [sessionToken]);

    useEffect(() => {
        setSelectedGroups(initial);
    }, [initial]);

    return (
        <SpaceBetween size='m'>
            <Header>Groups</Header>
            {error && <p>Error: {error.message}</p>}
            {stateGroups && <CardsEdit type="group" list={stateGroups} initial={selectedGroups} updateFunction={handleListChange} />}
        </SpaceBetween>
    )
};


export const Group = ({ sessionToken }) => {
    const { name } = useParams(); // Extract the name parameter from the route
    const [stateGroup, setGroup] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchGroup(sessionToken, name, setGroup, setError);
    }, [name, sessionToken]); // Dependency array includes name and sessionToken to re-fetch if they change

    return (
        <Container key={5}>
            {error && <p>Error: {error.message}</p>}
            <Box>
                {stateGroup ? (
                    <SpaceBetween size='m'>
                        <Container><Header actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button to="edit">Edit</Button>
                                <Button to="delete">Delete</Button>
                            </SpaceBetween>
                        }>{stateGroup.name}</Header></Container>
                        <HostList key="hosts" hosts={stateGroup.hosts} create={false} />
                        <GroupList key="children" groups={stateGroup.children} create={false} />
                        <Container><VariableList variables={stateGroup.vars} /></Container>
                    </SpaceBetween>
                ) : (
                    <p>Loading...</p> // Handling the case when hostData is undefined
                )}
            </Box>
        </Container>

    );
};

export const GroupEdit = ({ sessionToken }) => {
    const { name } = useParams(); // Extract the name parameter from the route
    const [stateGroup, setGroup] = useState(null);
    const [error, setError] = useState(null);
    const [fetchTrigger, setFetchTrigger] = useState(0); // State to trigger re-fetch
    const navigate = useNavigate();

    const handleVariablesChange = (newVars) => {
        setGroup({ ...stateGroup, newvars: newVars });
    };

    const handleHostsChange = (newHosts) => {
        setGroup({ ...stateGroup, newhosts: newHosts });
    };

    const handleChildrenChange = (newChildren) => {
        setGroup({ ...stateGroup, newchildren: newChildren });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newData = {
            name: stateGroup.name,
            vars: stateGroup.newvars || stateGroup.vars,
            hosts: stateGroup.newhosts || stateGroup.hosts,
            children: stateGroup.newchildren || stateGroup.children,
        }
        postGroup(sessionToken, newData, null, setError).then(() => {
            navigate('/groups');
        }).catch(err => {
            setError(err);
        });
    };

    const handleReset = (e) => {
        e.preventDefault();
        setFetchTrigger(prev => prev + 1);
    };

    useEffect(() => {
        fetchGroup(sessionToken, name, setGroup, setError);
    }, [name, sessionToken, fetchTrigger]);

    return (
        <Container key={5}>
            {error && <p>Error: {error.message}</p>}
            <Box>
                <form onSubmit={handleSubmit}>
                    {stateGroup ?
                        <SpaceBetween size='m'>
                            <Container><Header>{stateGroup.name}</Header></Container>
                            <Container><HostListEdit sessionToken={sessionToken} initial={stateGroup.hosts} updateFunction={handleHostsChange} /></Container>
                            <Container><GroupListEdit sessionToken={sessionToken} initial={stateGroup.children} updateFunction={handleChildrenChange} /></Container>
                            <Container><VariableUpdate variables={stateGroup.vars} updateFunction={handleVariablesChange} /></Container>
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button type="submit">Submit</Button>
                                <Button onClick={handleReset}>Reset</Button>
                            </SpaceBetween>
                        </SpaceBetween>
                        : (
                            <p>Loading...</p> // Handling the case when hostData is undefined
                        )}
                </form>
            </Box>
        </Container>

    );
};

export const GroupNew = ({ sessionToken }) => {
    const [stateGroup, setGroup] = useState({ name: '', vars: {}, hosts: [], children: [] });
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleNameChange = (name) => {
        setGroup({ ...stateGroup, name: name });
    };

    const handleVariablesChange = (newVars) => {
        setGroup({ ...stateGroup, newvars: newVars });
    };

    const handleHostsChange = (newHosts) => {
        setGroup({ ...stateGroup, newhosts: newHosts });
    };

    const handleChildrenChange = (newChildren) => {
        setGroup({ ...stateGroup, newchildren: newChildren });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newData = {
            name: stateGroup.name,
            vars: stateGroup.newvars || stateGroup.vars,
            hosts: stateGroup.newhosts || stateGroup.hosts,
            children: stateGroup.newchildren || stateGroup.children,
        }
        postGroup(sessionToken, newData, null, setError).then(() => {
            navigate('/groups');
        }).catch(err => {
            setError(err);
        });
    };

    return (
        <Container key={5}>
            {error && <p>Error: {error.message}</p>}
            <Box>
                <form onSubmit={handleSubmit}>
                    {stateGroup ?
                        <SpaceBetween size='m'>
                            <Container>
                                <SpaceBetween size='m'>
                                    <Header>Name</Header>
                                    <Input
                                        onChange={({ detail }) => handleNameChange(detail.value)}
                                        value={stateGroup.name}
                                        ariaRequired
                                        autoFocus
                                        disableBrowserAutocorrect
                                    />
                                </SpaceBetween>
                            </Container>
                            <Container><HostListEdit sessionToken={sessionToken} initial={stateGroup.hosts} updateFunction={handleHostsChange} /></Container>
                            <Container><GroupListEdit sessionToken={sessionToken} initial={stateGroup.children} updateFunction={handleChildrenChange} /></Container>
                            <Container><VariableUpdate variables={stateGroup.vars} updateFunction={handleVariablesChange} /></Container>
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button type="submit">Submit</Button>
                            </SpaceBetween>
                        </SpaceBetween>
                        : (
                            <p>Loading...</p> // Handling the case when hostData is undefined
                        )}
                </form>
            </Box>
        </Container>

    );
};

export const GroupDelete = ({ sessionToken }) => {
    const { name } = useParams(); // Extract the name parameter from the route
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        deleteGroup(sessionToken, name, null, setError).then(() => {
            navigate('/groups');
        }).catch(err => {
            setError(err);
        });
    };

    return (
        <Container key={7}>
            {error && <p>Error: {error.message}</p>}
            <Box>
                <form onSubmit={handleSubmit}>
                    <SpaceBetween size='m'>
                        <Container><Header>Remove group {name}</Header></Container>
                        <Container>
                            Are you sure you want to remove group {name}?
                        </Container>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button type="submit">Remove</Button>
                        </SpaceBetween>
                    </SpaceBetween>
                </form>
            </Box>
        </Container>
    );
};

export default Group;
