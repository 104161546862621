import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Link as CloudscapeLink } from "@cloudscape-design/components";

const Link = ({ to, children, ...props }) => {
    const navigate = useNavigate();

    return (
        <CloudscapeLink onClick={() => navigate(to)} {...props}>{children}</CloudscapeLink>
    );
};

export default Link;
