import axios from 'axios';
import ansibleConfig from '../config/ansible-exports';

const fetchData = async (path, sessionToken, setData, setError) => {
    if (sessionToken != null) {
        try {
            const url = `${ansibleConfig.api}/${path}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${sessionToken}`
                }
            });
            setData(response.data);
        } catch (err) {
            setError(err);
        }
    }
};

export const fetchHostList = async (sessionToken, setData, setError) => {
    fetchData("host", sessionToken, setData, setError);
};

export const fetchGroupList = async (sessionToken, setData, setError) => {
    fetchData("group", sessionToken, setData, setError);
};

export const fetchKnownHostList = async (sessionToken, setData, setError) => {
    fetchData("knownhosts", sessionToken, setData, setError);
};

export const fetchPublicKeyList = async (sessionToken, setData, setError) => {
    fetchData("publickeys", sessionToken, setData, setError);
};

export const fetchHost = async (sessionToken, name, setData, setError) => {
    fetchData(`host/${name}`, sessionToken, setData, setError);
};

export const fetchGroup = async (sessionToken, name, setData, setError) => {
    fetchData(`group/${name}`, sessionToken, setData, setError);
};

export const fetchKnownHost = async (sessionToken, name, algorithm, setData, setError) => {
    fetchData(`knownhost/${name}/${algorithm}`, sessionToken, setData, setError);
};

export const fetchPublicKey = async (sessionToken, name, algorithm, setData, setError) => {
    fetchData(`publickey/${name}/${algorithm}`, sessionToken, setData, setError);
};

export const postData = async (path, sessionToken, data, setData, setError) => {
    if (sessionToken != null) {
        try {
            const url = `${ansibleConfig.api}/${path}`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${sessionToken}`
                }
            });
            if (setData) {
                setData(response.data);
            }
        } catch (err) {
            setError(err);
        }
    }
};

export const postHost = async (sessionToken, data, setData, setError) => {
    postData("host", sessionToken, data, setData, setError);
};

export const postGroup = async (sessionToken, data, setData, setError) => {
    postData("group", sessionToken, data, setData, setError);
}

export const postKnownHost = async (sessionToken, data, setData, setError) => {
    postData("knownhost", sessionToken, data, setData, setError);
};

export const postPublicKey = async (sessionToken, data, setData, setError) => {
    postData("publickey", sessionToken, data, setData, setError);
};

export const deleteData = async (path, sessionToken, setData, setError) => {
    if (sessionToken != null) {
        try {
            const url = `${ansibleConfig.api}/${path}`;
            const response = await axios.delete(url, {
                headers: {
                    Authorization: `Bearer ${sessionToken}`
                }
            });
            if (setData) {
                setData(response.data);
            }
        } catch (err) {
            setError(err);
        }
    }
};

export const deleteHost = async (sessionToken, name, setData, setError) => {
    deleteData(`host/${name}`, sessionToken, setData, setError);
};

export const deleteGroup = async (sessionToken, name, setData, setError) => {
    deleteData(`group/${name}`, sessionToken, setData, setError);
}

export const deleteKnownHost = async (sessionToken, name, algorithm, setData, setError) => {
    deleteData(`knownhost/${name}/${algorithm}`, sessionToken, setData, setError);
};

export const deletePublicKey = async (sessionToken, name, algorithm, setData, setError) => {
    deleteData(`publickey/${name}/${algorithm}`, sessionToken, setData, setError);
};

export default fetchData;
