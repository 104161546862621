import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import ansibleConfig from '../config/ansible-exports';
import { Header, Container, SpaceBetween } from "@cloudscape-design/components"
import ReactEcharts from "echarts-for-react";


const capitalizeWords = (str) => str.replace(/\b\w/g, char => char.toUpperCase());


const transformAnsible = (input) => {
    // This function transforms each node recursively
    const transformNode = (key, node) => {
        const transformedNode = { name: key };

        // Transform children nodes, if they exist
        if (node.children && node.children.length > 0) {
            transformedNode.children = node.children.map(childKey => transformNode(childKey, input[childKey]));
        }

        // Transform host nodes, if they exist
        if (node.hosts && node.hosts.length > 0) {
            transformedNode.children = (transformedNode.children || []).concat(
                node.hosts.map(hostKey => {
                    const host = input._meta.hostvars[hostKey];
                    return {
                        name: hostKey,
                        value: host.ansible_host || ''
                    };
                })
            );
        }

        return transformedNode;
    };

    // Collect all host keys from hostvars
    const allHosts = Object.keys(input._meta.hostvars);

    // Function to find all hosts that are mentioned in any group
    const findAllGroupedHosts = () => {
        const findHosts = (node) => {
            let hosts = [];
            if (node.hosts) {
                hosts = hosts.concat(node.hosts);
            }
            if (node.children) {
                node.children.forEach(child => {
                    hosts = hosts.concat(findHosts(input[child]));
                });
            }
            return hosts;
        };
        return new Set(findHosts(input.all));
    };

    const groupedHosts = findAllGroupedHosts();

    // Identify ungrouped hosts
    const ungroupedHosts = allHosts.filter(host => !groupedHosts.has(host));

    // Create the 'ungrouped' node with ungrouped hosts
    const ungroupedNode = {
        name: "ungrouped",
        children: ungroupedHosts.map(hostKey => {
            const host = input._meta.hostvars[hostKey];
            return {
                name: hostKey,
                value: host.ansible_host || ''
            };
        })
    };

    // Transform the root node, and include the 'ungrouped' node if it has children
    const rootKey = 'all';
    const transformedRoot = {
        name: rootKey,
        children: input[rootKey].children.map(childKey => transformNode(childKey, input[childKey]))
    };

    if (ungroupedNode.children.length > 0) {
        transformedRoot.children.push(ungroupedNode);
    }

    return serieTemplate(transformedRoot, "ansible");
};


const transformNagios = (input) => {
    const hostvars = input._meta.hostvars;

    // This function builds a tree node and recursively adds child nodes
    const buildTree = (node) => {
        let result = {
            name: node,
            value: hostvars[node].ansible_host, // Stores the IP address from hostvars
            children: [] // Initializes an empty array for children
        };

        // Iterate over hostvars to find children of the current node
        Object.keys(hostvars).forEach(key => {
            if (hostvars[key].nagios_parent === node) {
                result.children.push(buildTree(key)); // Recursively build tree for each child
            }
        });

        return result;
    };

    let trees = [];

    // Build trees for all hosts whose 'nagios_parent' is explicitly set to 'root'
    Object.keys(hostvars).forEach(key => {
        if (hostvars[key].nagios_parent === 'root') {
            trees.push(buildTree(key)); // Start building a tree from each root node
        }
    });

    return serieTemplate(trees[0], "ansible");
};


const serieTemplate = (data, chart) => ({
    type: 'tree',
    name: `tree-${chart}`,
    data: [data],
    top: '10%',
    left: '8%',
    bottom: '22%',
    right: '20%',
    symbolSize: 7,
    edgeShape: 'polyline',
    edgeForkPosition: '63%',
    initialTreeDepth: 6,
    orient: 'LR',
    lineStyle: {
        width: 2
    },
    label: {
        backgroundColor: '#fff',
        position: 'left',
        verticalAlign: 'middle',
        align: 'right'
    },
    leaves: {
        label: {
            position: 'right',
            verticalAlign: 'middle',
            align: 'left'
        }
    },
    emphasis: {
        focus: 'descendant'
    },
    expandAndCollapse: true,
    animationDuration: 550,
    animationDurationUpdate: 750
});


const Inventory = ({ chart }) => {
    const [inventory, setInventory] = useState(null);
    const [error, setError] = useState(null);
    const [series, setSeries] = useState(null);
    const option = {
        tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove'
        },
        series: series
    };


    useEffect(() => {
        // Define an async function to fetch the session
        const fetchInventory = async () => {
            try {
                const session = await fetchAuthSession();
                const { idToken } = session.tokens ?? {};
                axios.get(`${ansibleConfig.api}/inventory`, {
                    headers: {
                        Authorization: `Bearer ${idToken.toString()}`
                    }
                })
                    .then(response => {
                        setInventory(response.data);
                    });
            } catch (err) {
                setError(err);
            }
        };

        // Call the async function
        fetchInventory();
    }, []); // Empty dependency array means this runs once when the component mounts

    useEffect(() => {
        if (inventory) {
            if (chart === "nagios") setSeries(transformNagios(inventory));
            else
                setSeries(transformAnsible(inventory));
        }
    }, [inventory, chart]);

    return (
        <Container key={8}
            header={<Header
                variant="h2">{chart ? capitalizeWords(chart) : 'Inventory'}</Header>}>

            {error && <p>Error: {error.message}</p>}
            {inventory ? (
                <SpaceBetween size="m">
                    {
                        (chart === "raw") ? <pre>{JSON.stringify(inventory, null, 2)}</pre> : (
                            series &&
                            < ReactEcharts option={option} style={{ height: '50vh' }} />
                        )
                    }
                    {/* <div><pre>{JSON.stringify(option.series, null, 2)}</pre></div> */}
                </SpaceBetween>
            ) : (
                <p>Loading inventory...</p>
            )}
        </Container>

    );
};

export default Inventory;
