import * as React from "react";
import { useAuthenticator } from '@aws-amplify/ui-react';
import TopNavigation from "@cloudscape-design/components/top-navigation";
import { useNavigate } from "react-router-dom";
import ansibleConfig from "../config/ansible-exports";


const Menu = () => {
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    let navigate = useNavigate()

    const itemClick = (e) => {
        console.log("Logout:", e)
        console.log("User:", user)
        e.preventDefault();
        if (e.detail.id === "signout") signOut()
    }

    if (!user) {
        return <p>Loading user...</p>
    }

    return (
        <TopNavigation

            identity={{
                onFollow: (() => { navigate("/") }),
                title: <div className='title'>Ansible Dashboard</div>,

            }}
            utilities={[
                {
                    type: "button",
                    text: "This repo",
                    href: "https://github.com/Berghmans/ansible-dashboard",
                    external: true,
                    externalIconAriaLabel: " (opens in a new tab)"
                },
                {
                    type: "menu-dropdown",
                    text: "Ansible Tools",
                    items: ansibleConfig.tools.map((tool) => {
                        return {
                            id: tool,
                            text: tool,
                            href: `https://github.com/Berghmans/${tool}`,
                            external: true,
                            externalIconAriaLabel: " (opens in new tab)"
                        }
                    })
                },
                {
                    type: "menu-dropdown",
                    text: "Ansible Roots",
                    items: ansibleConfig.roots.map((root) => {
                        return {
                            id: root,
                            text: root,
                            href: `https://github.com/Berghmans/${root}`,
                            external: true,
                            externalIconAriaLabel: " (opens in new tab)"
                        }
                    })
                },
                {
                    type: "menu-dropdown",
                    text: "Ansible Roles",
                    items: ansibleConfig.roles.map((role) => {
                        return {
                            id: role,
                            text: role,
                            href: `https://github.com/Berghmans/${role}`,
                            external: true,
                            externalIconAriaLabel: " (opens in new tab)"
                        }
                    })
                },
                {
                    type: "menu-dropdown",
                    text: `You (${user.username})`,
                    onItemClick: ((e) => { itemClick(e) }),
                    iconName: "user-profile",
                    items: [
                        { id: "username", text: user.username },
                        {
                            id: "signout", text: "Sign out"
                        }
                    ]

                },



            ]}
            i18nStrings={{
                searchIconAriaLabel: "Search",
                searchDismissIconAriaLabel: "Close search",
                overflowMenuTriggerText: "More",
                overflowMenuTitleText: "All",
                overflowMenuBackIconAriaLabel: "Back",
                overflowMenuDismissIconAriaLabel: "Close menu"
            }}
        />

    );
}

export default Menu
