import React, { useState, useEffect } from 'react';
import '@aws-amplify/ui-react/styles.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { fetchAuthSession } from 'aws-amplify/auth';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import Menu from "./views/Menu"
import Layout from './views/Layout'
import Inventory from './components/Inventory';
import Unauthorized from './views/Unauthorized';
import { Host, HostEdit, HostNew, HostList, HostDelete } from './components/Host';
import { Group, GroupEdit, GroupNew, GroupList, GroupDelete } from './components/Group';
import { KnownHost, KnownHostEdit, KnownHostNew, KnownHostList, KnownHostDelete } from './components/KnownHost';
import { PublicKey, PublicKeyEdit, PublicKeyNew, PublicKeyList, PublicKeyDelete } from './components/PublicKey';

const App = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const [sessionToken, setSessionToken] = useState(null);
  const [userGroups, setUserGroups] = useState([]);
  const [error, setError] = useState(null);
  const requiredGroup = "ansible"; // Define the required group

  useEffect(() => {
    const fetchSessionToken = async () => {
      try {
        const session = await fetchAuthSession();
        const { idToken } = session.tokens ?? {};
        if (idToken) {
          setSessionToken(idToken.toString());

          const groups = idToken.payload['cognito:groups'] || [];
          setUserGroups(groups);
        }
      } catch (err) {
        setError(err);
      }
    };

    if (!sessionToken) {
      fetchSessionToken();
    }
  }, [sessionToken]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (sessionToken && !userGroups.includes(requiredGroup)) {
    return <Unauthorized />;
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Struct />,
      errorElement: <div>Something went wrong!</div>,
      children: [
        { path: "inventory/raw", element: <Inventory chart={"raw"} /> },
        { path: "inventory/ansible", element: <Inventory chart={"ansible"} /> },
        { path: "inventory/nagios", element: <Inventory chart={"nagios"} /> },
        { path: "inventory/knownhosts", element: <KnownHostList sessionToken={sessionToken} dashboard={true} /> },
        { path: "inventory/publickeys", element: <PublicKeyList sessionToken={sessionToken} dashboard={true} /> },
        { path: "hosts", element: <HostList sessionToken={sessionToken} /> },
        { path: "hosts/create", element: <HostNew sessionToken={sessionToken} /> },
        { path: "host/:name", element: <Host sessionToken={sessionToken} /> },
        { path: "host/:name/edit", element: <HostEdit sessionToken={sessionToken} /> },
        { path: "host/:name/delete", element: <HostDelete sessionToken={sessionToken} /> },
        { path: "groups", element: <GroupList sessionToken={sessionToken} /> },
        { path: "groups/create", element: <GroupNew sessionToken={sessionToken} /> },
        { path: "group/:name", element: <Group sessionToken={sessionToken} /> },
        { path: "group/:name/edit", element: <GroupEdit sessionToken={sessionToken} /> },
        { path: "group/:name/delete", element: <GroupDelete sessionToken={sessionToken} /> },
        { path: "knownhosts", element: <KnownHostList sessionToken={sessionToken} /> },
        { path: "knownhosts/create", element: <KnownHostNew sessionToken={sessionToken} /> },
        { path: "knownhost/:name/:algorithm", element: <KnownHost sessionToken={sessionToken} /> },
        { path: "knownhost/:name/:algorithm/edit", element: <KnownHostEdit sessionToken={sessionToken} /> },
        { path: "knownhost/:name/:algorithm/delete", element: <KnownHostDelete sessionToken={sessionToken} /> },
        { path: "publickeys", element: <PublicKeyList sessionToken={sessionToken} /> },
        { path: "publickeys/create", element: <PublicKeyNew sessionToken={sessionToken} /> },
        { path: "publickey/:name/:algorithm", element: <PublicKey sessionToken={sessionToken} /> },
        { path: "publickey/:name/:algorithm/edit", element: <PublicKeyEdit sessionToken={sessionToken} /> },
        { path: "publickey/:name/:algorithm/delete", element: <PublicKeyDelete sessionToken={sessionToken} /> },
      ]
    }
  ])

  return route === "authenticated" ? <RouterProvider router={router} /> : <Authenticator hideSignUp loginMechanisms={['username']} />;
}

const Struct = () => (
  <>
    <Menu />
    <Layout />
  </>
);

export default App;
