import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

const Unauthorized = () => {
    const { signOut } = useAuthenticator((context) => [context.user]);
    return (
        <div>
            <p>Unauthorized: You do not have access to this application.</p>
            <button onClick={signOut}>Log out</button>
        </div>
    )
}

export default Unauthorized;
