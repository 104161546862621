const ansibleConfig = {
    roots: [
        "ansible-root-homeautomation",
        "ansible-downloadinfrastructure",
    ],
    roles: [
        "ansible-role-home-assistant",
        "ansible-role-private-ca",
        "ansible-role-docker-host",
        "ansible-role-influxdb",
        "ansible-role-zwave-to-mqtt",
        "ansible-role-user-management",
        "ansible-role-squid",
        "ansible-role-raspberry-pi",
        "ansible-role-pi-hole",
        "ansible-role-openvpn-server",
        "ansible-role-nginx",
        "ansible-role-nagios-server",
        "ansible-role-nagios-nrpe-server",
        "ansible-role-nagios-client",
        "ansible-role-meter-module",
        "ansible-role-iot-store",
        "ansible-role-http-to-mqtt",
        "ansible-role-grafana",
        "ansible-role-dynamic-dns",
        "ansible-role-dlinfra",
        "ansible-role-backup-server",
        "ansible-role-backup-local",
        "ansible-role-autofs",
    ],
    tools: [
        "ansible-dashboard",
        "ansible-inventory",
        "ansible-tool",
    ],
    api: "https://api.berghmans.it/ansible",
};

export default ansibleConfig;
