import React, { useEffect, useState } from 'react';
import { Box, Header, SpaceBetween, Cards, AttributeEditor, Input } from "@cloudscape-design/components"
import Link from '../utils/Link';

export const VariableList = ({ variables }) => {
    return (
        <Cards
            ariaLabels={{
                itemSelectionLabel: (e, t) => `select ${t.key}`,
                selectionGroupLabel: "Item selection"
            }}
            cardDefinition={{
                header: item => (
                    <Link fontSize="heading-l"
                        variant="secondary"
                    >{item.key}</Link>
                ),
                sections: [{
                    id: 'value',
                    header: 'Value',
                    content: item => item.value,
                }]
            }}
            cardsPerRow={[
                { cards: 1 },
                { minWidth: 500, cards: 2 }
            ]}
            items={
                variables && Object.entries(variables).map(([name, value]) => (
                    {
                        key: name,
                        value: value
                    }
                ))
            }
            loadingText="Loading variables"
            empty={
                <Box
                    margin={{ vertical: "xs" }}
                    textAlign="center"
                    color="inherit"
                >
                    <SpaceBetween size="m">
                        <b>No variables</b>
                    </SpaceBetween>
                </Box>
            }
            header={
                <Header>Variables</Header>
            }
        />
    )
};

export const VariableUpdate = ({ type, variables, updateFunction = null }) => {
    const transformVariables = (variables) => {
        return variables ? Object.entries(variables).map(([name, value]) => (
            {
                key: name,
                value: value
            }
        )) : []
    };
    const [items, setItems] = useState(transformVariables(variables));

    const updateVariables = (items) => {
        setItems(items);
        if (updateFunction) {
            updateFunction(items.reduce((acc, item) => {
                if (item.key && item.value) {
                    acc[item.key] = item.value;
                }
                return acc;
            }, {}));
        }
    }

    useEffect(() => {
        setItems(transformVariables(variables));
    }, [variables]);

    return (
        <SpaceBetween size='m'>
            <Header>Variables</Header>
            <AttributeEditor
                onAddButtonClick={() => updateVariables([...items, {}])}
                onRemoveButtonClick={({ detail: { itemIndex } }) => {
                    if (!(type === 'knownhost' || type === "publickey")) {
                        const tmpItems = [...items];
                        tmpItems.splice(itemIndex, 1);
                        updateVariables(tmpItems);
                    }
                }}
                items={items}
                addButtonText="Add new item"
                removeButtonText="Remove"
                definition={[
                    {
                        label: "Key",
                        control: (item, index) => (
                            <Input
                                value={item.key}
                                placeholder="Enter key"
                                onChange={event => {
                                    const tmpItems = [...items];
                                    tmpItems[index].key = event.detail.value;
                                    updateVariables(tmpItems);
                                }}
                            />
                        )
                    },
                    {
                        label: "Value",
                        control: (item, index) => (
                            <Input
                                value={item.value}
                                placeholder="Enter value"
                                onChange={event => {
                                    const tmpItems = [...items];
                                    tmpItems[index].value = event.detail.value;
                                    updateVariables(tmpItems);
                                }}
                            />
                        )
                    }
                ]}
                disableAddButton={(type === 'knownhost' || type === "publickey")}
                empty="No items associated with the resource."
            /></SpaceBetween>
    )
};

export default VariableList;
