import React, { useEffect, useState } from 'react';
import { fetchKnownHostList, fetchKnownHost, postKnownHost, deleteKnownHost } from '../utils/apiHelper';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Header, Container, SpaceBetween } from "@cloudscape-design/components"
import { VariableList, VariableUpdate } from './Variable';
import Button from '../utils/Button';
import { Cards, CardsEdit } from '../utils/Cards';


export const KnownHostList = ({ sessionToken = null, knownhosts = [], create = true, dashboard = false }) => {
    const [stateKnownHosts, setKnownHosts] = useState(knownhosts);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!sessionToken) {
            setKnownHosts(knownhosts);
        }
    }, [sessionToken, knownhosts]);

    useEffect(() => {
        fetchKnownHostList(sessionToken, setKnownHosts, setError);
    }, [sessionToken]);

    return (
        <Container key={6}>
            {error && <p>Error: {error.message}</p>}
            {dashboard ?
                <>
                    <Header>Known Hosts</Header>
                    <div>
                    <pre style={{ maxWidth: '100vh', whiteSpace: 'pre-wrap' }}>
                    {stateKnownHosts.map((knownhost) => (
                                `# ${knownhost.name}\n${knownhost.host_hash} ${knownhost.algorithm} ${knownhost.public_key}\n`
                            )).join('')}
                        </pre>
                    </div>
                </>
                :
                <Cards type="knownhost" list={stateKnownHosts} header={
                    <Header actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            {create ? <Button to="create">New Known Host</Button> : ''}</SpaceBetween>
                    }>Known Hosts</Header>
                } />
            }
        </Container>
    )
};


export const KnownHostListEdit = ({ sessionToken, initial = [], updateFunction = null }) => {
    const [stateKnownHosts, setKnownHosts] = useState(null);
    const [selectedKnownHosts, setSelectedKnownHosts] = useState(initial);
    const [error, setError] = useState(null);

    const handleListChange = (knownhosts) => {
        setSelectedKnownHosts(knownhosts);
        if (updateFunction) {
            updateFunction(knownhosts);
        }
    };

    useEffect(() => {
        fetchKnownHostList(sessionToken, setKnownHosts, setError);
    }, [sessionToken]);

    useEffect(() => {
        setSelectedKnownHosts(initial);
    }, [initial]);

    return (
        <SpaceBetween size='m'>
            <Header>Known Hosts</Header>
            {error && <p>Error: {error.message}</p>}
            {stateKnownHosts && <CardsEdit type="knownhost" list={stateKnownHosts} initial={selectedKnownHosts} updateFunction={handleListChange} />}
        </SpaceBetween>
    )
};


export const KnownHost = ({ sessionToken }) => {
    const { name, algorithm } = useParams(); // Extract the name parameter from the route
    const [stateKnownHost, setKnownHost] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchKnownHost(sessionToken, name, algorithm, setKnownHost, setError);
    }, [name, algorithm, sessionToken]); // Dependency array includes name and sessionToken to re-fetch if they change

    return (
        <Container key={7}>
            {error && <p>Error: {error.message}</p>}
            <Box>
                {stateKnownHost ? (
                    <SpaceBetween size='m'>
                        <Container><Header actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button to="edit">Edit</Button>
                                <Button to="delete">Delete</Button>
                            </SpaceBetween>
                        }>{stateKnownHost.name}</Header></Container>
                        <Container><VariableList variables={stateKnownHost} /></Container>
                    </SpaceBetween>
                ) : (
                    <p>Loading...</p> // Handling the case when knownhostData is undefined
                )}
            </Box>
        </Container>

    );
};

export const KnownHostEdit = ({ sessionToken }) => {
    const { name, algorithm } = useParams(); // Extract the name parameter from the route
    const [stateKnownHost, setKnownHost] = useState(null);
    const [stateEditKnownHost, setEditKnownHost] = useState(null);
    const [error, setError] = useState(null);
    const [fetchTrigger, setFetchTrigger] = useState(0); // State to trigger re-fetch
    const navigate = useNavigate();

    const handleVariablesChange = (newKnownHost) => {
        setEditKnownHost(newKnownHost);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newData = {
            name: stateEditKnownHost.name || stateKnownHost.name,
            algorithm: stateEditKnownHost.algorithm || stateKnownHost.algorithm,
            public_key: stateEditKnownHost.public_key || stateKnownHost.public_key,
            host_hash: stateEditKnownHost.host_hash || stateKnownHost.host_hash,
        }
        postKnownHost(sessionToken, newData, null, setError).then(() => {
            navigate('/knownhosts');
        }).catch(err => {
            setError(err);
        });
    };

    const handleReset = (e) => {
        e.preventDefault();
        setFetchTrigger(prev => prev + 1);
    };

    useEffect(() => {
        fetchKnownHost(sessionToken, name, algorithm, setKnownHost, setError);
    }, [name, algorithm, sessionToken, fetchTrigger]);

    return (
        <Container key={7}>
            {error && <p>Error: {error.message}</p>}
            <Box>
                <form onSubmit={handleSubmit}>
                    {stateKnownHost ? (
                        <SpaceBetween size='m'>
                            <Container><Header>{stateKnownHost.name}</Header></Container>
                            <Container>
                                <VariableUpdate type="knownhost" variables={stateKnownHost} updateFunction={handleVariablesChange} />
                            </Container>
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button type="submit">Submit</Button>
                                <Button onClick={handleReset}>Reset</Button>
                            </SpaceBetween>
                        </SpaceBetween>
                    ) : (
                        <p>Loading...</p> // Handling the case when knownhostData is undefined
                    )}
                </form>
            </Box>
        </Container>
    );
};

export const KnownHostNew = ({ sessionToken }) => {
    const [stateKnownHost, setKnownHost] = useState({ name: "", algorithm: "", public_key: "", host_hash: "" });
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleVariablesChange = (newKnownHost) => {
        setKnownHost({ ...stateKnownHost, ...newKnownHost });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postKnownHost(sessionToken, stateKnownHost, null, setError).then(() => {
            navigate('/knownhosts');
        }).catch(err => {
            setError(err);
        });
    };

    return (
        <Container key={7}>
            {error && <p>Error: {error.message}</p>}
            <Box>
                <form onSubmit={handleSubmit}>
                    <SpaceBetween size='m'>
                        <Container>
                            <VariableUpdate type="knownhost" variables={stateKnownHost} updateFunction={handleVariablesChange} />
                        </Container>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button type="submit">Submit</Button>
                        </SpaceBetween>
                    </SpaceBetween>
                </form>
            </Box>
        </Container>
    );
};

export const KnownHostDelete = ({ sessionToken }) => {
    const { name, algorithm } = useParams(); // Extract the name parameter from the route
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        deleteKnownHost(sessionToken, name, algorithm, null, setError).then(() => {
            navigate('/knownhosts');
        }).catch(err => {
            setError(err);
        });
    };

    return (
        <Container key={7}>
            {error && <p>Error: {error.message}</p>}
            <Box>
                <form onSubmit={handleSubmit}>
                    <SpaceBetween size='m'>
                        <Container><Header>Remove Known Host {name}</Header></Container>
                        <Container>
                            Are you sure you want to remove known host {name} with algorithm {algorithm}?
                        </Container>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button type="submit">Remove</Button>
                        </SpaceBetween>
                    </SpaceBetween>
                </form>
            </Box>
        </Container>
    );
};

export default KnownHost;
