// index.js
import React from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "./utils/AuthContext";
import App from './App'
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { Amplify } from 'aws-amplify'

import "@aws-amplify/ui-react/styles.css"
import { ThemeProvider, createTheme, defaultTheme } from "@aws-amplify/ui-react";



import { awsExports } from './config/aws-exports';
Amplify.configure(awsExports);


const studioTheme = createTheme(defaultTheme)

ReactDOM.createRoot(document.getElementById('root')).render(
  <ThemeProvider theme={studioTheme}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </ThemeProvider >
)

reportWebVitals();

// Source of examples: https://github.com/build-on-aws/building-reactjs-gen-ai-apps-with-amazon-bedrock-javascript-sdk/tree/main/reactjs-gen-ai-apps/src
