// aws-exports.js

const awsConfig = {
    Auth: {
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_16yTXPyUD',
        userPoolWebClientId: '765nf0ksq0sta5dni9da6qgbr8',
        identityPoolId: 'eu-west-1:ef632853-f2de-4414-902d-d94e008fc52e',
    },
};


const awsExports = {
    Auth: {
        Cognito: {
            userPoolClientId: awsConfig.Auth.userPoolWebClientId,
            userPoolId: awsConfig.Auth.userPoolId,
            identityPoolId: awsConfig.Auth.identityPoolId,
            loginWith: {
                oauth: {
                    domain: 'berghmans.auth.eu-west-1.amazoncognito.com',
                    scopes: ['openid', 'email', 'phone', 'profile', 'aws.cognito.signin.user.admin'],
                    redirectSignIn: ['http://localhost:3000/', 'https://ansible.berghmans.it/'],
                    redirectSignOut: ['http://localhost:3000/', 'https://ansible.berghmans.it/'],
                    responseType: 'code',
                },
                username: 'true',
            }
        }
    }
}

export { awsConfig, awsExports};
