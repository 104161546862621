import React, { useEffect, useState } from 'react';
import { fetchPublicKeyList, fetchPublicKey, postPublicKey, deletePublicKey } from '../utils/apiHelper';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Header, Container, SpaceBetween } from "@cloudscape-design/components"
import { VariableList, VariableUpdate } from './Variable';
import Button from '../utils/Button';
import { Cards, CardsEdit } from '../utils/Cards';


export const PublicKeyList = ({ sessionToken = null, publickeys = [], create = true, dashboard = false }) => {
    const [statePublicKeys, setPublicKeys] = useState(publickeys);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!sessionToken) {
            setPublicKeys(publickeys);
        }
    }, [sessionToken, publickeys]);

    useEffect(() => {
        fetchPublicKeyList(sessionToken, setPublicKeys, setError);
    }, [sessionToken]);

    return (
        <Container key={6}>
            {error && <p>Error: {error.message}</p>}
            {dashboard ?
                <>
                    <Header>Public Keys</Header>
                    <div>
                        <pre style={{ maxWidth: '100vh', whiteSpace: 'pre-wrap' }}>
                            {statePublicKeys.map((publickey) => (
                                `# ${publickey.name}\n${publickey.algorithm} ${publickey.public_key} ${publickey.host_hash}\n`
                            )).join('')}
                        </pre>
                    </div>
                </>
                :
                <Cards type="publickey" list={statePublicKeys} header={
                    <Header actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            {create ? <Button to="create">New Public Key</Button> : ''}</SpaceBetween>
                    }>Public Keys</Header>
                } />
            }
        </Container>
    )
};


export const PublicKeyListEdit = ({ sessionToken, initial = [], updateFunction = null }) => {
    const [statePublicKeys, setPublicKeys] = useState(null);
    const [selectedPublicKeys, setSelectedPublicKeys] = useState(initial);
    const [error, setError] = useState(null);

    const handleListChange = (publickeys) => {
        setSelectedPublicKeys(publickeys);
        if (updateFunction) {
            updateFunction(publickeys);
        }
    };

    useEffect(() => {
        fetchPublicKeyList(sessionToken, setPublicKeys, setError);
    }, [sessionToken]);

    useEffect(() => {
        setSelectedPublicKeys(initial);
    }, [initial]);

    return (
        <SpaceBetween size='m'>
            <Header>Public Keys</Header>
            {error && <p>Error: {error.message}</p>}
            {statePublicKeys && <CardsEdit type="publickey" list={statePublicKeys} initial={selectedPublicKeys} updateFunction={handleListChange} />}
        </SpaceBetween>
    )
};


export const PublicKey = ({ sessionToken }) => {
    const { name, algorithm } = useParams(); // Extract the name parameter from the route
    const [statePublicKey, setPublicKey] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchPublicKey(sessionToken, name, algorithm, setPublicKey, setError);
    }, [name, algorithm, sessionToken]); // Dependency array includes name and sessionToken to re-fetch if they change

    return (
        <Container key={7}>
            {error && <p>Error: {error.message}</p>}
            <Box>
                {statePublicKey ? (
                    <SpaceBetween size='m'>
                        <Container><Header actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button to="edit">Edit</Button>
                                <Button to="delete">Delete</Button>
                            </SpaceBetween>
                        }>{statePublicKey.name}</Header></Container>
                        <Container><VariableList variables={statePublicKey} /></Container>
                    </SpaceBetween>
                ) : (
                    <p>Loading...</p> // Handling the case when publickeyData is undefined
                )}
            </Box>
        </Container>

    );
};

export const PublicKeyEdit = ({ sessionToken }) => {
    const { name, algorithm } = useParams(); // Extract the name parameter from the route
    const [statePublicKey, setPublicKey] = useState(null);
    const [stateEditPublicKey, setEditPublicKey] = useState(null);
    const [error, setError] = useState(null);
    const [fetchTrigger, setFetchTrigger] = useState(0); // State to trigger re-fetch
    const navigate = useNavigate();

    const handleVariablesChange = (newPublicKey) => {
        setEditPublicKey(newPublicKey);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newData = {
            name: stateEditPublicKey.name || statePublicKey.name,
            algorithm: stateEditPublicKey.algorithm || statePublicKey.algorithm,
            public_key: stateEditPublicKey.public_key || statePublicKey.public_key,
            host_hash: stateEditPublicKey.host_hash || statePublicKey.host_hash,
        }
        postPublicKey(sessionToken, newData, null, setError).then(() => {
            navigate('/publickeys');
        }).catch(err => {
            setError(err);
        });
    };

    const handleReset = (e) => {
        e.preventDefault();
        setFetchTrigger(prev => prev + 1);
    };

    useEffect(() => {
        fetchPublicKey(sessionToken, name, algorithm, setPublicKey, setError);
    }, [name, algorithm, sessionToken, fetchTrigger]);

    return (
        <Container key={7}>
            {error && <p>Error: {error.message}</p>}
            <Box>
                <form onSubmit={handleSubmit}>
                    {statePublicKey ? (
                        <SpaceBetween size='m'>
                            <Container><Header>{statePublicKey.name}</Header></Container>
                            <Container>
                                <VariableUpdate type="publickey" variables={statePublicKey} updateFunction={handleVariablesChange} />
                            </Container>
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button type="submit">Submit</Button>
                                <Button onClick={handleReset}>Reset</Button>
                            </SpaceBetween>
                        </SpaceBetween>
                    ) : (
                        <p>Loading...</p> // Handling the case when publickeyData is undefined
                    )}
                </form>
            </Box>
        </Container>
    );
};

export const PublicKeyNew = ({ sessionToken }) => {
    const [statePublicKey, setPublicKey] = useState({ name: "", algorithm: "", public_key: "", host_hash: "" });
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleVariablesChange = (newPublicKey) => {
        setPublicKey({ ...statePublicKey, ...newPublicKey });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postPublicKey(sessionToken, statePublicKey, null, setError).then(() => {
            navigate('/publickeys');
        }).catch(err => {
            setError(err);
        });
    };

    return (
        <Container key={7}>
            {error && <p>Error: {error.message}</p>}
            <Box>
                <form onSubmit={handleSubmit}>
                    <SpaceBetween size='m'>
                        <Container>
                            <VariableUpdate type="publickey" variables={statePublicKey} updateFunction={handleVariablesChange} />
                        </Container>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button type="submit">Submit</Button>
                        </SpaceBetween>
                    </SpaceBetween>
                </form>
            </Box>
        </Container>
    );
};

export const PublicKeyDelete = ({ sessionToken }) => {
    const { name, algorithm } = useParams(); // Extract the name parameter from the route
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        deletePublicKey(sessionToken, name, algorithm, null, setError).then(() => {
            navigate('/publickeys');
        }).catch(err => {
            setError(err);
        });
    };

    return (
        <Container key={7}>
            {error && <p>Error: {error.message}</p>}
            <Box>
                <form onSubmit={handleSubmit}>
                    <SpaceBetween size='m'>
                        <Container><Header>Remove Public Key {name}</Header></Container>
                        <Container>
                            Are you sure you want to remove known host {name} with algorithm {algorithm}?
                        </Container>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button type="submit">Remove</Button>
                        </SpaceBetween>
                    </SpaceBetween>
                </form>
            </Box>
        </Container>
    );
};

export default PublicKey;
